import {
  FETCH_INVOICES_LOADING,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_ERROR,
  FETCH_INVOICES_DETAILS,
  FETCH_INVOICE_PAYMENT_DATA_LOADING,
  FETCH_INVOICE_PAYMENT_DATA,
} from "actions/invoices";


const initialState = {
  loading: false,
  list: [],
  fetchedInvoice: null,
  error: null,
  invoicePaymentData:null,
  invoicePaymentDataLoading:false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case FETCH_INVOICES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_INVOICES_DETAILS:
      return {
        ...state,
        fetchedInvoice: action.payload,
      };
    case FETCH_INVOICES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_INVOICE_PAYMENT_DATA:
      return {
        ...state,
        invoicePaymentData: action.payload,
      };
    case FETCH_INVOICE_PAYMENT_DATA_LOADING:
      return {
        ...state,
        invoicePaymentDataLoading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;