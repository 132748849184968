
import axios from 'axios';
import { get } from 'lodash';
import { URL } from 'config/config';
import { showAlert } from './alert';

export const FETCH_INVOICES_LOADING = 'FETCH_INVOICES_LOADING';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_ERROR = 'FETCH_INVOICES_ERROR';
export const FETCH_INVOICES_DETAILS = 'FETCH_INVOICES_DETAILS';
export const FETCH_INVOICE_PAYMENT_DATA_LOADING = 'FETCH_INVOICE_PAYMENT_DATA_LOADING';
export const FETCH_INVOICE_PAYMENT_DATA = 'FETCH_INVOICE_PAYMENT_DATA';


const setInvoicesLoading = (value = true) => ({
    type: FETCH_INVOICES_LOADING,
    payload: value
});
const setInvoices = payload => ({
    type: FETCH_INVOICES_SUCCESS,
    payload
});
const setInvoicesError = payload => ({
    type: FETCH_INVOICES_ERROR,
    payload
});
const setInvoicesDetails = payload => ({
    type: FETCH_INVOICES_DETAILS,
    payload
});
const setInvoicePaymentDataLoading = payload => ({
    type: FETCH_INVOICE_PAYMENT_DATA_LOADING,
    payload
});
const setInvoicePaymentData = payload => ({
    type: FETCH_INVOICE_PAYMENT_DATA,
    payload
});


export const fetchInvoices = (pagination = '') => async dispatch => {
    dispatch(setInvoicesLoading(true));
    try {
        const response = await axios.get(`${URL.INVOICES}${pagination ? ('?' + pagination) : ''}`);
        if (response && response.data.success) {
            const data = get(response, 'data.objects', []);
            dispatch(setInvoices(data));
            dispatch(setInvoicesLoading(false));
            return response?.data
        }
        dispatch(setInvoicesLoading(false));
    } catch (err) {
        dispatch(setInvoicesError(err));
        dispatch(setInvoicesLoading(false));
    };
}

export const createInvoice = (payload) => async dispatch => {
    try {
        const response = await axios.post(URL.INVOICES, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', []);
            //dispatch(fetchInvoices())
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const fetchInvoiceById = (invoiceId) => async dispatch => {
    dispatch(setInvoicesLoading(true));
    try {
        const response = await axios.get(`${URL.INVOICES}/${invoiceId}`);
        if (response && response.data.success) {
            const data = get(response, 'data.object', {});
            dispatch(setInvoicesDetails(data));
            dispatch(setInvoicesLoading(false));
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}
export const fetchInvoicePaymentData = (invoiceId) => async dispatch => {
    dispatch(setInvoicePaymentDataLoading(true));
    try {
        let payload = {
            id: invoiceId
        }
        const response = await axios.post(`${URL.INVOICES}/create-order`, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', {});
            dispatch(setInvoicePaymentData(data));
            dispatch(setInvoicePaymentDataLoading(false));
            return data
        } else {
            dispatch(showAlert('error', response?.data?.message ?? 'Something went wrong: Payment Gateways are not available now'))
            dispatch(setInvoicePaymentDataLoading(false));
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message));
            dispatch(setInvoicePaymentDataLoading(false));
        }
        
    };
}

export const editInvoice = (payload) => async dispatch => {
    try {
        const response = await axios.put(URL.INVOICES, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', []);
            //dispatch(fetchInvoices())
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const deleteInvoice = (invoiceId) => async dispatch => {
    try {
        const response = await axios.delete(URL.INVOICES + `/${invoiceId}`);
        if (response && response.data.success) {
            //dispatch(fetchInvoices())
            return true
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const markAsPaid = (invoiceId) => async dispatch => {
    try {
        let payload = {
            id: invoiceId
        }
        const response = await axios.put(`${URL.INVOICES}/mark-as-paid`, payload);
        if (response && response.data.success) {
            dispatch(showAlert('success', response?.data?.message ?? "Invoice marked as PAID"));
            const data = get(response, 'data', {});
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const issueInvoice = (invoiceId) => async dispatch => {
    try {
        let payload = {
            id: invoiceId
        }
        const response = await axios.post(`${URL.INVOICES}/send`, payload);
        if (response && response.data.success) {
            dispatch(showAlert('success', response?.data?.message ?? "Invoice sent successfully"));
            const data = get(response, 'data', {});
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const downloadInvoice = (invoiceId) => async dispatch => {
    try {
        const response = await axios.get(`${URL.INVOICES}/download?invoiceId=${invoiceId}`, {
            responseType: "blob", // Ensures binary data handling
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `QZ-Invoice-${invoiceId}.pdf` || "quezone-invoice.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error("Error downloading file:", error);
    }
};