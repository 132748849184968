import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Email } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Loading from 'components/Loading/Loading';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
import { loginUser, setOtpRequestState, fetchUser } from 'actions/auth';
import ForgotPasswordPage from './ForgotPasswordPage'
import ConfirmAccount from './confirmAccount'
import ForceChangePassword from './../Profile/force-change-password'
import { eUserType, userStatus } from "constants.js"

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimation: 'cardHidden',
      loginEmail: '',
      loginEmailState: '',
      loginPassword: '',
      loginPasswordState: '',
      isForgotPassword: false,
      confirmAccountModalState : false,
      passwordShown: false,
      openForceChangePassword:false,
      userEmail:''
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ cardAnimation: '' });
    }, 700);
    // const ga = window.gapi && window.gapi.auth2 ? window.gapi.auth2.getAuthInstance() : null;
    // if (!ga) createScript();

    const {userDetail, history} = this.props;
    const userSub = userDetail.id || localStorage.getItem('userSub');
    if (userSub) {
      this.props.fetchUser(userSub, history)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userDetail !== this.props.userDetail) {
      const {userDetail, history} = this.props;
      //console.log("userDetail changed", userDetail);
      if(userDetail?.id && history?.action == "POP"){
        const redirectPath = (new URLSearchParams(window.location.search).get("redirectPath") || "/dashboard").replace(/^(?!\/)/, "/");
        history.push(redirectPath);
      }
    }
  }

  openForgotPasswordDialog = () => {
    this.setState({ isForgotPassword: true });
  };

  verifyEmail = value => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

  verifyLength = (value, length) => value.length >= length;

  loginClick = async (e) => {
    e.preventDefault();
    if (this.state.loginEmailState === '') {
      this.setState({ loginEmailState: 'error' });
    }
    if (this.state.loginPasswordState === '') {
      this.setState({ loginPasswordState: 'error' });
    }
    if (this.state.loginEmailState === 'success' && this.state.loginPasswordState === 'success') {
      const {loginUser} = this.props;
      let redirectPath = new URLSearchParams(window.location.search).get("redirectPath") || "";
      redirectPath = redirectPath ? redirectPath.replace(/^(?!\/)/, "/") : '';  // add slash on start if not
      const status = await loginUser(this.state, this.props.history, redirectPath);
      //console.log("status resposnse >>",status)
      if(status == userStatus.changePassword ){
        this.openPasswordChangeDialog();
      }
    }
  };

  openPasswordChangeDialog= () => {
    this.setState({ openForceChangePassword: true });
    this.setState({ userEmail : this.state.loginEmail }); //to prevent autofilled email on force change api
  };
  closePasswordChangeDialog = () => {
    this.setState({ openForceChangePassword: false });
  };
  onForceChangePassword= (data) => {
    console.log("onForceChangePassword" , data )
    
    this.setState({
        loginEmail: data.email,
        loginPassword:data.newPassword,
      },
      ()=>{
        this.closePasswordChangeDialog();
        this.loginClick()
      }
    )
  }


  change(event, stateName, type) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName]: event.target.value, [`${stateName}State`]: 'success' });
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 8)) {
          this.setState({ [stateName]: event.target.value, [`${stateName}State`]: 'success' });
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        break;
      default:
        break;
    }
    this.setState({ isForgotPassword: false });
  }

  closeForgotPasswordDialog = () => {
    this.setState({ isForgotPassword: false })
    this.props.setOtpRequestState(false)
  }

  openConfirmAccountModal = () => {
    this.setState({
      confirmAccountModalState : true,
    })
  }

  closeConfirmAccountModal = () => {
    this.setState({
      confirmAccountModalState : false,
    })
  }

  textShown = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    })
  }

  render() {
    const {
      classes,
      userLoading,
      history,
    } = this.props;
    const {
      loginEmail,
      isForgotPassword,
      confirmAccountModalState,
    } = this.state;

    const [forgotPasswordTitle, forgotClass, confirmAccountTitle, confirmClass] = !this.verifyEmail(loginEmail)
      ? ['Enter your email above to reset password!', 'button-pad-bot text-right hover-pointer','Enter your email above to confirm account!', 'button-pad-bot text-right hover-pointer']
      : ['', 'button-pad-bot hover-pointer text-right hover-main-color','', 'button-pad-bot hover-pointer text-right hover-main-color'];
    return (
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={6} md={4} >
              {userLoading ? (
                <Loading />
              ) : (
                  <form onSubmit={this.loginClick}>
                    <Card login className={classes[this.state.cardAnimation]}>
                      <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                        color="rose"
                      >
                        <br/>
                        <h4 className={classes.cardText}>Log in</h4>
                      </CardHeader>
                      <CardBody>
                        <CustomInput
                          labelText="Email..."
                          success={this.state.loginEmailState === 'success'}
                          error={this.state.loginEmailState === 'error'}
                          id="loginEmail"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: 'email',
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            onChange:event => this.change(event, 'loginEmail', 'email')
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          success={this.state.loginPasswordState === 'success'}
                          error={this.state.loginPasswordState === 'error'}
                          id="loginPassword"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: (this.state.passwordShown ? 'text' : 'password'),
                            endAdornment: (
                              <InputAdornment position="end">
                                {this.state.passwordShown ? <VisibilityIcon className={classes.inputAdornmentIcon} onClick={this.textShown}/> : <VisibilityOffIcon className={classes.inputAdornmentIcon} onClick={this.textShown}/>}
                              </InputAdornment>
                            ),
                            onChange:event => this.change(event, 'loginPassword', 'password'),
                          }}
                        />
                        <div>
                          <Button
                            simple
                            block
                            data-test-id="loginBtn"
                            color="rose"
                            size="lg"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                        <div>
                          <Link to="/register">
                            <Button color="rose" simple size="lg" block>
                              Register as Business User
                          </Button>
                          </Link>
                        </div>

                        <ForgotPasswordPage
                          open={isForgotPassword}
                          email={loginEmail}
                          history={history}
                          closeDialog={this.closeForgotPasswordDialog}
                        />

                        <ConfirmAccount
                          modalState={confirmAccountModalState}
                          email={loginEmail}
                          closeDialog={this.closeConfirmAccountModal}
                        />

                        <Tooltip
                          title={forgotPasswordTitle}
                          placement="top-end"
                        >
                          <Button
                            color="transparent"
                            size="sm"
                            onClick={this.openForgotPasswordDialog}
                            className={forgotClass}
                          >
                            Forgot Password?
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title={confirmAccountTitle}
                          placement="top-end"
                        >
                          <Button
                            color="transparent"
                            size="sm"
                            onClick={this.openConfirmAccountModal}
                            className={confirmClass}
                            style={{float:"right"}}
                          >
                            Confirm Account
                          </Button>
                        </Tooltip>
                      </CardBody>
                    </Card>
                  </form>
                )}
            </GridItem>
          </GridContainer>
          <ForceChangePassword
            open={this.state.openForceChangePassword}
            onChangePassword={this.onForceChangePassword}
            onClose={this.closePasswordChangeDialog}
            email={this.state.userEmail}
            history={history}
          />
        </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userLoading: state.user.userLoading,
    verify: state.user.verify,
    userDetail: state.user.userDetail,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (value, history, redirectPath ) => dispatch(loginUser(value, history, redirectPath)),
    setOtpRequestState: (stateValue) => dispatch(setOtpRequestState(stateValue)),
    fetchUser: (userSub, history) =>  dispatch(fetchUser(userSub, history)),
  }
}

export default compose(
  withStyles(loginPageStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoginPage);
