import axios from 'axios'
import store from 'index'
import { showAlert } from 'actions/alert'
import { checkTokens } from 'actions/auth'

axios.interceptors.request.use(
  async (request) => {
    const accessToken = await checkTokens() // ensure the tokens are upto date
    if (!!accessToken) {
      request.headers.common['Authorization'] = `Bearer ${accessToken}`
    }
    // Auth.currentSession either returns the current valid tokens or if they are expired refreshes them and returns the same
    return request
  }
)

axios.interceptors.response.use(
  resp => {
    if (resp.data.message) {
      if (!resp.data.success) {
        // temporary measure to suppress 'no survey found error message'
        const ignoreMessageList = [
          "Oops! Survey not found", 
          "Credentials not available",
          "Upgrade plan to view blacklisted-users" 
        ] 
        if (!ignoreMessageList.includes(resp.data.message)){ 
          let message = resp.data.message;
          if(message.includes("UNPROCESSABLE_ENTITY") && message.includes("INSTRUMENT_DECLINED")  ){
            message = "We couldn’t complete your payment. Please try again or use a different method";
          }
          store.dispatch(showAlert('error', message));
        }        
      }
    }
    return resp;
  },
  error => {
    if (error.response && error.response.data.message) {
      store.dispatch(showAlert('error', error.response.data.message));
    }
  }
);
