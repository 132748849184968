import { downloadInvoice, fetchInvoiceById, fetchInvoicePaymentData } from "actions/invoices";
import Loading from "components/Loading/Loading";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, CardContent, Typography, Divider, Grid, Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, SwipeableDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment-timezone";
import Alert from '@material-ui/lab/Alert';
import axios from "axios";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { grey } from "@material-ui/core/colors";
import Button from 'components/CustomButtons/Button';
import { roseColor } from "assets/jss/material-dashboard-pro-react";
import { BeatLoader } from "react-spinners";
import { URL } from "config/config";
import LoadingModal from "components/CustomModal/LoadingModal";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
    },
    card: {
        maxWidth: 600,
        padding: theme.spacing(3),
        boxShadow: theme.shadows[3],
        backgroundColor: '#fff',
        position: 'relative',
    },
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subTitle: {
        fontWeight: 'semibold',
        textAlign: 'center',
        //opacity:0.8,
        marginBottom: theme.spacing(2),
    },
    total: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: theme.spacing(3),
        gap: 10,
    },
    puller: {
        width: 30,
        height: 6,
        backgroundColor: theme.palette.type === "light" ? grey[300] : grey[900],
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
    },
    container: {
        padding: theme.spacing(2),
        margin: "15px auto",
        width: "100%",
        minHeight: "70vh",
    },
    paymentOptions: {
        marginBottom: theme.spacing(2),
    },
    pgCard: {
        padding: theme.spacing(2),
    },
    radioGroup: {
        margin: theme.spacing(2, 0),
    },
    formLabel: {
        color: "gray", // Default color
        "&.Mui-focused": {
            color: roseColor[0], // Change this to your preferred color
        },
    },
    ribbon: {
        position: 'absolute',
        top: 20,
        right: -40,
        transform: 'rotate(45deg)',
        width: 160,
        textAlign: 'center',
        color: '#fff',
        padding: '4px 0',
        fontSize: '0.8rem',
        fontWeight: 'bold',
        zIndex: 1,
      },
}));

const InvoiceSummary = (props) => {

    const classes = useStyles();

    const { invoiceId } = props.match.params;
    const invoiceDetails = useSelector(state => state.invoices.fetchedInvoice);
    const invoicePaymentData = useSelector(state => state.invoices.invoicePaymentData);
    const invoicePaymentDataLoading = useSelector(state => state.invoices.invoicePaymentDataLoading);

    const [showGatewaySelection, setShowGatewaySelection] = useState(false);

    const [invoiceWithPaymentData, setInvoceWithPaymentData] = useState({});

    const isLoading = useSelector(state => state.invoices.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        if (invoiceId) {
            dispatch(fetchInvoiceById(invoiceId));
        }
    }, [])

    useEffect(() => {
        if (invoicePaymentData?.paymentDetails?.razorpay || invoicePaymentData?.paymentDetails?.paypal) {
            setInvoceWithPaymentData(invoicePaymentData);
        }
    }, [invoicePaymentData])

    const onProceedToPayment = async () => {
        const response = await dispatch(fetchInvoicePaymentData(invoiceId));
        if (response) {
            setShowGatewaySelection(true);
        }
    }

    const getRibbonColor = (status) => {
        if (status === 'PAID') return '#4caf50'; // green
        if (status === 'CANCELED') return '#9e9e9e'; // grey
        // Default for UNPAID and any other status
        return '#f44336'; // red
    };

    return (
        <>
            {isLoading ? <Loading /> :
                invoiceDetails ?
                    <>
                        <Container className={classes.root}>
                            <Card className={classes.card}>
                                {['PAID', 'CANCELED'].includes(invoiceDetails?.invoiceStatus) &&
                                    <div
                                        className={classes.ribbon}
                                        style={{ backgroundColor: getRibbonColor(invoiceDetails?.invoiceStatus) }}
                                    >
                                        {invoiceDetails?.invoiceStatus}
                                    </div>
                                }
                                <CardContent>
                                    <Typography variant="h5" className={classes.title} gutterBottom>
                                        Invoice Summary
                                    </Typography>
                                    <Typography variant="body2" className={classes.subTitle} gutterBottom>
                                        #{invoiceDetails?.invoiceNumber}
                                    </Typography>
                                    <Grid container spacing={2} style={{ marginTop: 16 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                                Date: {moment(invoiceDetails?.invoiceDate * 1000).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                                Due Date: {moment(invoiceDetails?.dueDate * 1000).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container spacing={2} style={{ marginTop: 16 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">Organization Name:</Typography>
                                            <Typography variant="body2">{invoiceDetails?.organizationName}</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                                            {/*
                                        <Typography variant="body1">Billing Address:</Typography>
                                        <Typography variant="body2">123 Main Street, City</Typography>
                                        */}
                                        </Grid>
                                    </Grid>
                                    <Divider style={{ margin: '16px 0' }} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={9}>
                                            <Typography variant="body1">Description</Typography>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                                            <Typography variant="body1">Price</Typography>
                                        </Grid>
                                        {invoiceDetails?.items?.map((item, index) =>
                                            <React.Fragment key={index}>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">{item?.description}</Typography>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: 'right' }}>
                                                    <Typography variant="body2">{item?.amount} {invoiceDetails?.currency}</Typography>
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                    <Divider style={{ margin: '16px 0' }} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">Subtotal:</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                                            <Typography variant="body1">{invoiceDetails?.subTotal} {invoiceDetails?.currency}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h6">Total:</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                                            <Typography variant="h6" className={classes.total}>{invoiceDetails?.subTotal} {invoiceDetails?.currency}</Typography>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            onClick={() => dispatch(downloadInvoice(invoiceDetails?.id))}
                                        >
                                            Download Invoice
                                        </Button>
                                        {!['PAID', 'CANCELED'].includes(invoiceDetails?.invoiceStatus) &&
                                            <Button
                                                color="rose"
                                                onClick={onProceedToPayment}
                                                disabled={invoicePaymentDataLoading}
                                            >
                                                <div style={{ marginRight: 10 }}>Proceed to Payment</div>
                                                {invoicePaymentDataLoading ? <BeatLoader size={8} color={'#fff'} /> : null}
                                            </Button>
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                        </Container>
                    </>
                    :
                    <Box style={{ margin: 15 }}>
                        <Alert severity="warning" style={{ justifyContent: 'center' }}>Requested Data Not Available</Alert>
                    </Box>
            }

            <PaymentGatewayHandler invoiceDetails={invoiceWithPaymentData} open={showGatewaySelection} onClose={() => setShowGatewaySelection(false)} />
        </>
    )
}

export default InvoiceSummary;


const PaymentGatewayHandler = ({ open, invoiceDetails, onClose = () => { } }) => {

    const classes = useStyles();

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentData, setPaymentData] = useState({});
    const [selectedMethod, setSelectedMethod] = useState("");
    const [showGatewayModal, setShowGatewayModal] = useState(false);
    const [isPaypalScriptLoaded, setIsPaypalScriptLoaded] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const userData = useSelector(state => state.user.userDetail);
    const dispatch = useDispatch();

    const closeGatewayModal = () => {
        setShowGatewayModal(false);
        onClose();
    }

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const handleRazorpayCallback = async (payload) => {
        try {
            closeGatewayModal();
            const response = await axios.post(URL.RAZORPAY_CALLBACK, payload);
            dispatch(fetchInvoiceById(invoiceDetails?.id));
            document.body.style.overflow = '' // Fix for scroll freeze after payment success redirection
        } catch (e) {
            console.log("Error [handleRazorpayCallback] :", e)
        }
    }
    const handlePaypalCallback = async (payload) => {
        try {
            closeGatewayModal();
            setShowLoading(true);
            setTimeout(async()=>{
                const response = await axios.post(URL.PAYPAL_CALLBACK, payload);
                await dispatch(fetchInvoiceById(invoiceDetails?.id));
                setShowLoading(false);
            }, 100);
        } catch (e) {
            console.log("Error [handleRazorpayCallback] :", e)
            setShowLoading(false);
        }
    }

    const payWithRazorpay = () => {
        if (window?.Razorpay) {
            const options = {
                key: paymentData?.razorpay?.keyId, // Enter the Key ID generated from the Dashboard
                amount: ((paymentData?.amount ?? 0) * 100).toString(),
                currency: paymentData?.currency,
                name: "Quezone",
                description: `Payment portal`,
                image: "https://cdn.que.zone/public-assets/images/logo.png",
                order_id: paymentData?.razorpay?.orderId,
                //callback_url : paymentData?.razorpay?.callbackUrl,
                handler: (response) => {
                    const payload = {
                        orderId: paymentData?.razorpay?.orderId,
                        invoiceId: invoiceDetails?.id,
                        ...response
                    }
                    handleRazorpayCallback(payload);
                },
                prefill: {
                    name: userData?.givenName ?? "",
                    email: userData?.email ?? "",
                    contact: userData?.telephone ?? "",
                },
                theme: {
                    color: "#00f",
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } else {
            console.warn("Razorpay script is not loaded !!")
        }
    }
    const initializePaypalButton = () => {
        if (isPaypalScriptLoaded && window?.paypal) {
            try {
                window.paypal.Buttons({
                    style: {
                        layout: 'vertical', // horizontal or vertical
                        color: 'gold', // Specify the button color: gold, blue, silver, white, black
                        shape: 'pill', // rectangular, pill, or rect
                        label: 'paypal', // paypal or checkout
                        height: 40, // button height in pixels
                    },
                    createOrder: (data, actions) => {
                        return paymentData?.paypal?.orderId; // Use the existing order ID
                    },
                    onApprove: (data, actions) => {
                        let payload = {
                            invoiceId: invoiceDetails?.id,
                            ...data
                        }
                        handlePaypalCallback(payload);
                        //console.log("Paypal Payment Approved", data, actions)
                    }
                }).render('#paypal-button-container');
            } catch (e) {
                console.log("Paypal Script error !!", e)
            }
        }
    }

    useEffect(() => {
        if (isPaypalScriptLoaded && selectedMethod == "paypal") {
            initializePaypalButton()
        }
    }, [selectedMethod, isPaypalScriptLoaded]);

    /* Re render paypal buttons on modal close/open */
    useEffect(() => {
        if (showGatewayModal && selectedMethod == "paypal") {
            setTimeout(() => initializePaypalButton(), 50);
        }
    }, [showGatewayModal]);

    useEffect(() => {
        setShowGatewayModal(open);
    }, [open])

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }, []);

    useEffect(() => {
        let paymentData_ = invoiceDetails?.paymentDetails ?? {};
        paymentData_.amount = invoiceDetails?.subTotal ?? 0;
        paymentData_.currency = invoiceDetails?.currency;

        let paymentMethods_ = [];//["razorpay", "paypal"];
        paymentMethods_ = invoiceDetails?.paymentDetails?.razorpay?.keyId && invoiceDetails?.paymentDetails?.razorpay?.orderId ? [...paymentMethods_, "razorpay"] : paymentMethods_;
        paymentMethods_ = invoiceDetails?.paymentDetails?.paypal?.clientId && invoiceDetails?.paymentDetails?.paypal?.orderId ? [...paymentMethods_, "paypal"] : paymentMethods_;

        const paymentStatus_ = invoiceDetails?.invoiceStatus ?? "";

        setSelectedMethod((paymentMethods_?.length ? paymentMethods_[0] : ""));
        setPaymentMethods(paymentMethods_);
        setPaymentStatus(paymentStatus_);
        setPaymentData(paymentData_);

        if (paymentMethods_.includes("paypal") && paymentStatus_ !== "PAID") {
            const currency = paymentData_?.currency ?? "INR";
            const clientId = paymentData_?.paypal?.clientId ?? "";
            if (clientId) {
                loadScript(`https://www.paypal.com/sdk/js?client-id=${clientId}`).then(() => setIsPaypalScriptLoaded(true));
            }
        }
    }, [invoiceDetails])

    return (
        <>
            <LoadingModal openModal={showLoading}/>
            <SwipeableDrawer
                anchor="bottom"
                open={showGatewayModal}
                onClose={closeGatewayModal}
                onOpen={() => setShowGatewayModal(true)}
                swipeAreaWidth={56}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: false,
                    disableEnforceFocus: true, // Disable focus trap
                    disableAutoFocus: true,    // Prevent automatic focusin
                }}
            >
                <Container maxWidth="md" className={classes.container}>
                    <Box className={classes.puller} />
                    <Grid container justify="space-around" alignItems="stretch" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" className={classes.paymentOptions}>
                                Payment Options
                            </Typography>
                            <Card className={classes.pgCard}>
                                <CardContent>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" className={classes.formLabel} >Choose Payment Gateway</FormLabel>
                                        <RadioGroup
                                            name="payment-methods-group"
                                            value={selectedMethod}
                                            onChange={(e) => setSelectedMethod(e.target.value)}
                                            className={classes.radioGroup}
                                        >
                                            {paymentMethods.map((gateway, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={gateway}
                                                    control={<Radio />}
                                                    label={gateway.replace(/\b\w/g, (char) => char.toUpperCase())}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </CardContent>
                            </Card>
                            <Box style={{ marginTop: 15 }}>
                                {selectedMethod == "razorpay" ?
                                    <Button color="rose" onClick={payWithRazorpay}>
                                        Pay Now
                                    </Button>
                                    : selectedMethod == "paypal" ?
                                        <>
                                            <div id="paypal-button-container"></div>
                                        </>
                                        : null
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </SwipeableDrawer>
        </>
    )
}
